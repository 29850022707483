


















































































import proyectoModule from "@/store/modules/proyecto-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxSortable } from "devextreme-vue/sortable";
import { Component, Vue } from "vue-property-decorator";
import { proyecto } from "@/shared/dtos/proyecto";
import { RouterNames } from "@/router/routernames";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    DxScrollView,
    DxSortable,
    KanbanList: () => import("@/components/TypeView/Kanban_List.vue"),
    XBtnActiveInActive: () =>
      import("@/components/TypeView/x-btnActiveInActive.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ProyectoKanban extends Vue {
  public lists: any[] = [];
  public search: string = "";
  public shw_type: string = "active";
  created() {
    this.change_data_activos(this.shw_type);
    enumeracionModule.getenumeraciones_fromtipo(1);
  }

  public change_data_activos(activos: string) {
    this.shw_type = activos;
    if (activos === "active") {
      return proyectoModule.getproyectosActivos();
    } else {
      return proyectoModule.getproyectosInActivos();
    }
  }

  public get proyectos() {
    this.lists = [];
    this.estados.forEach((status) => {
      if (this.search.trim() !== "") {
        this.lists.push({
          estado: status,
          tareas: proyectoModule.proyectos.filter((task) => {
            return (
              task.id_estado === status.id &&
              task.nombre.toLowerCase().includes(this.search.toLowerCase())
            );
          }),
        });
      } else {
        this.lists.push({
          estado: status,
          tareas: proyectoModule.proyectos.filter(
            (task) => task.id_estado === status.id
          ),
        });
      }
    });
    return "";
  }
  public get estados() {
    return enumeracionModule.enumeraciones;
  }

  get get_style_height() {
    let alto = this.$vuetify.breakpoint.height;
    return "height:" + (alto - 218) + "px";
  }

  ValueOf(valor: any) {
    return UtilsString.ValueOf(valor);
  }

  onTaskDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onTaskDrop(e: any) {
    e.itemData = e.fromData.tareas[e.fromIndex];
    e.itemData.id_estado = e.toData.estado.id;
    e.itemData.orden = e.toIndex;
    //llamo a la api
    proyectoModule
      .modificarproyecto_sin_cargando(new proyecto(e.itemData))
      .then(() =>
        proyectoModule.getproyectos_sin_cargando(this.shw_type === "active")
      );
  }

  get_style() {
    let alto = this.$vuetify.breakpoint.height;
    return "max-height:" + (alto - 218) + "px";
  }

  /*getPriorityClass(task: proyecto) {
    return `priority-${task.id_prioridad}`;
  }*/

  getPriorityStyle(task: proyecto) {
    return ` background:${task.prioridad.color}`;
  }

  onListReorder(e: any) {
    const list = this.lists.splice(e.fromIndex, 1)[0];
    this.lists.splice(e.toIndex, 0, list);

    const status = this.estados.splice(e.fromIndex, 1)[0];
    this.estados.splice(e.toIndex, 0, status);
  }

  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(proyecto: proyecto) {
    this.abrir_formulario(UtilsString.ValueOf(proyecto.id));
  }

  abrir_formulario(id_user: string) {
    this.$router.push({
      name: RouterNames.proyectosformulario,
      params: { id: id_user },
    });
  }

  to_link() {
    this.$router.push({ name: RouterNames.proyectoslista });
  }
}
